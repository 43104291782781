import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import Button from "../components/common/button";
import NewsLetters from "../components/common/newsletter";
import Product from "../components/common/product";
import { StoreContext } from "../context/storeContext";
// import Popup from "../components/common/popup";
import CartPopup from "../components/common/popup/Cart";
import Seo from "../components/common/SEO";

export default function Products({ data }) {
  const { addVariantToCart, checkout, addedToCart, setAddedToCart } =
    React.useContext(StoreContext);
  const { sanityProducts, allShopifyProduct } = data;
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (addedToCart) {
      setShow(true);
    }
  }, [addedToCart]);

  const hidePopup = () => {
    setAddedToCart(false);
    setShow(false);
  };

  useEffect(() => {
    const sorted = allShopifyProduct?.edges
      .filter(({ node }) => node.metafield?.value)
      .sort(function ({ node: a }, { node: b }) {
        return parseInt(a?.metafield?.value) - parseInt(b?.metafield?.value);
      });
    const unSorted = allShopifyProduct?.edges.filter(
      ({ node }) => node.metafield?.value === undefined
    );
    setProducts([...sorted, ...unSorted]);
  }, [data]);

  return (
    <Layout>
      <Seo
        title={sanityProducts?.seo?.metaTitle}
        description={sanityProducts?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading
              title={sanityProducts?.title}
              caption={sanityProducts?.caption}
            />
            <div className="mt-[52px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9">
              {products.length > 0 &&
                products.map(({ node }, index) => {
                  return (
                    <div key={index}>
                      <Product
                        description={node?.description}
                        name={node?.title}
                        image={
                          node?.featuredMedia?.preview?.image?.gatsbyImageData
                        }
                        slug={`/products/${node?.handle}`}
                        variants={node.variants}
                        addToCart={addVariantToCart}
                      />
                    </div>
                  );
                })}
            </div>
            <div className="mt-[70px]">
              <Link to="/faq">
                <Button text="Have A Question?" type="dark" />
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-[70px]">
          <NewsLetters small={true} />
        </div>
        {/* <Popup showPopup={showPopup} setShowPopUp={setShowPopup} /> */}
      </div>
      {checkout?.lines?.edges.length > 0 && show && (
        <CartPopup list={checkout.lines?.edges} onClick={hidePopup} />
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityProducts {
      title
      caption
      seo {
        metaTitle
        metaDescription
      }
    }
    allShopifyProduct(
      filter: { collections: { elemMatch: { title: { eq: "MIX" } } } }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          title
          handle
          description
          featuredMedia {
            preview {
              image {
                gatsbyImageData(height: 285, width: 285)
              }
            }
          }
          handle
          id
          storefrontId
          metafield(key: "product_placement", namespace: "mix") {
            value
            key
            namespace
          }
          variants {
            id
            availableForSale
            displayName
            title
            price
            storefrontId
            media {
              preview {
                image {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;
